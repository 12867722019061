import { DateTime } from 'luxon'
import {
  CalendarIcon,
  ClipboardListIcon,
  ClockIcon,
  HomeIcon,
  MailIcon,
  TruckIcon,
  UserIcon,
  ArrowLeftIcon,
  ArrowRightIcon,
} from '@heroicons/react/solid'
import { formatMoney } from '../Money'
import { motion } from 'framer-motion'
import { useState } from 'react'
import { Spinner } from '../Spinner'
import { ConditionalWrapper } from '../ConditionalWrapper'
import { useTranslation } from 'react-i18next'
import { useSiteName } from '../../useSiteName'
import { useFormatFromNumberOfMinutes } from '../Duration'
import { useGetVoucherDeliveryOption } from '../OrderHooks'

export const LoadingBasketItem = () => (
  <div className="flex space-x-3 items-start animate-pulse">
    <div className="aspect-offering w-[60px] bg-gray-200" />
    <div className="flex-1 space-y-1">
      <div className="w-2/3 bg-gray-200 h-6" />
      <div className="w-1/4 bg-gray-200 h-5" />
      <div className="w-1/4 bg-gray-200 h-5" />
    </div>
  </div>
)

const variants = {
  hidden: { y: '-100px', opacity: 0 },
  show: { y: 0, opacity: 1 },
  exit: { x: '-100%' },
}

export const kebabCase = (string) =>
  string
    ?.replace(/([a-z])([A-Z])/g, '$1-$2')
    .replace(/[\s_]+/g, '-')
    .replace(/[/%]+/g, '')
    .toLowerCase()

export const BasketItem = ({
  item,
  onRemove,
  canRemove = true,
  canEdit = false,
  onEdit,
  clickable = false,
  withImages = false,
  reportAnalytics = false,
  hidePrices = false,
  overridePriceAmount = null,
  onSeeDetails,
  variant = 'default',
}) => {
  const duration = useFormatFromNumberOfMinutes(item.duration)
  const [isRemoving, setRemoving] = useState(false)
  const [deliveryDetailsModalOpen, setDeliveryDetailsModalOpen] = useState(false)
  const PHYSICAL_DELIVERY_METHOD = 'physical'
  const DIGITAL_DELIVERY_METHOD = 'email'

  const handleRemove = async () => {
    setRemoving(true)
    await onRemove(item)

    if (reportAnalytics) {
      window.gtag('event', 'remove_from_cart', {
        currency: item.currency.toUpperCase(),
        value: parseFloat((item.total_cost / 100).toFixed(2)),
        items: [
          {
            affiliation: useSiteName(),
            item_id: item.offering_id,
            item_name: item.offering_name,
          },
        ],
      })
    }
  }

  const _canEdit =
    ['package', 'appointment', 'session', 'area_booking', 'voucher'].includes(item.offering_type) &&
    canEdit

  const isBookingItem = (item) => {
    const bookingTypes = ['appointment', 'area_booking', 'session', 'package', 'table_reservation']

    return bookingTypes.includes(item.offering_type)
  }

  const isPackageContainingHotelRoomReservation = (item) => {
    if (item.offering_type !== 'package') {
      return false
    }

    return (item.package_items ?? []).some(
      (item) => item.offering_type === 'hotel_room_reservation'
    )
  }

  const isAcrossMultipleDays = (item) => {
    if (item.offering_type !== 'package') {
      return false
    }

    // If any of the package item dates have a different date to the item,
    // then it's across multiple days.
    return (item.package_items ?? []).some((packageItem) => {
      const packageItemDate = DateTime.fromISO(packageItem.date)
      const itemDate = DateTime.fromISO(item.date)

      return !packageItemDate.hasSame(itemDate, 'day')
    })
  }

  const { t } = useTranslation()
  const guestNames = item.guests
    .map((guest) => (guest.is_lead_booker ? t('frontend.check_availability.me') : guest.name))
    .filter(Boolean)

  const VoucherDeliverySummary = ({ item }) => {
    const {
      isLoading,
      isSuccess,
      data: { data: voucherDeliveryOption } = {},
    } = useGetVoucherDeliveryOption(item.item_configuration['delivery_option_id'])

    return (
      <div className="text-gray-500 text-sm flex flex-col space-y-1">
        <div className="flex items-start space-x-2">
          <HomeIcon className="w-4 h-4 text-gray-400 mt-0.5 flex-shrink-0" />
          <div className="flex flex-col">
            <span>{item.item_configuration.address_line1}</span>
            <span>{item.item_configuration.address_line2}</span>
            <span>
              {item.item_configuration.county} · {item.item_configuration.postcode}
            </span>
            <span>{item.item_configuration.country}</span>
          </div>
        </div>
        {isSuccess && (
          <div className="flex items-start space-x-2">
            <TruckIcon className="w-4 h-4 text-gray-400" />
            <span>
              {voucherDeliveryOption.name} @{' '}
              {formatMoney({
                amount: voucherDeliveryOption.price,
                currencey: voucherDeliveryOption.currency ?? 'GBP',
              })}
            </span>
          </div>
        )}
      </div>
    )
  }

  return (
    <motion.li
      key={item.id}
      variants={variants}
      className={`${variant === 'default' ? 'bg-white border border-black/15 shadow-sm rounded-md overflow-hidden' : ''}`}
    >
      <div className={`${variant === 'default' ? 'p-3' : ''} flex w-full space-x-3 items-start`}>
        {withImages && (
          <div className="flex-shrink-0">
            <div className="aspect-square w-[70px] h-[70px] overflow-hidden bg-gray-200 rounded-md">
              {item.image && (
                <img
                  src={item.image.url}
                  alt={item.offering_name}
                  className="w-full h-full object-center object-cover"
                />
              )}
            </div>
          </div>
        )}

        <div className="flex-1 w-full">
          {!item.validity.valid && (
            <div className="bg-yellow-100 text-yellow-600 rounded-md p-2 text-xs mb-2">
              <ul>
                {item.validity.errors.map((error, index) => (
                  <li key={index}>{error.message}</li>
                ))}
              </ul>
            </div>
          )}
          <div className="flex items-start">
            <div className="flex-1 space-y-1">
              {item.guests.length > 0 && (
                <div className="text-xs flex tracking-wide space-x-1 items-center font-medium text-gray-500">
                  <UserIcon className="w-3 h-3 opacity-50" />
                  <span>
                    {guestNames.length === 0
                      ? t('frontend.check_availability.me')
                      : guestNames.join(', ')}
                  </span>
                </div>
              )}
              <div className="block font-semibold">
                <ConditionalWrapper
                  condition={clickable}
                  wrapper={(children) => (
                    <a
                      href={`/items/${item.offering_id}/${kebabCase(item.offering_name)}`}
                      title={item.offering_name}
                      className="hover:underline"
                    >
                      {children}
                    </a>
                  )}
                >
                  {item.offering_name}
                </ConditionalWrapper>
              </div>

              <div className="text-xs space-y-1">
                {isBookingItem(item) && isPackageContainingHotelRoomReservation(item) && (
                  <CheckInOutTimes item={item} />
                )}

                {isBookingItem(item) && !isPackageContainingHotelRoomReservation(item) && (
                  <div className="text-gray-500 text-sm items-center flex space-x-1">
                    <CalendarIcon className="w-4 h-4 text-gray-400" />
                    <span>{DateTime.fromISO(item.date).toLocaleString(DateTime.DATE_MED)}</span>
                  </div>
                )}

                {isBookingItem(item) && item.offering_type !== 'package' && (
                  <div className="text-gray-500 text-sm items-center flex space-x-1">
                    <ClockIcon className="w-4 h-4 text-gray-400" />
                    <span>
                      {DateTime.fromJSDate(new Date(item.time)).toLocaleString(
                        DateTime.TIME_SIMPLE
                      )}
                      {' · '}
                      {duration}
                    </span>
                  </div>
                )}

                {item.offering_type === 'hotel_room_reservation' && (
                  <div className="text-gray-500 text-sm">{item.item_configuration.rate_name}</div>
                )}

                {item.offering_type === 'appointment_enquiry' && (
                  <>
                    <span className="inline-flex items-center rounded-full bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">
                      Enquiry
                    </span>
                    <div className="text-gray-500 text-sm items-center flex space-x-1">
                      <CalendarIcon className="w-4 h-4 text-gray-400" />
                      <span>
                        {DateTime.fromISO(item.item_configuration.time_from).toLocaleString(
                          DateTime.DATE_MED
                        )}
                      </span>
                    </div>
                    <div className="text-gray-500 text-sm items-center flex space-x-1">
                      <ClockIcon className="w-4 h-4 text-gray-400" />
                      <span>
                        {DateTime.fromJSDate(
                          new Date(item.item_configuration.time_from)
                        ).toLocaleString(DateTime.TIME_SIMPLE)}
                        -
                        {DateTime.fromJSDate(
                          new Date(item.item_configuration.time_to)
                        ).toLocaleString(DateTime.TIME_SIMPLE)}
                      </span>
                    </div>
                  </>
                )}

                {item.offering_type === 'voucher' && (
                  <div>
                    {window.featureFlags.includes('voucher_delivery_method') &&
                      item.item_configuration.delivery_method_type === PHYSICAL_DELIVERY_METHOD && (
                        <VoucherDeliverySummary item={item} />
                      )}
                    {item.item_configuration.delivery_method_type === DIGITAL_DELIVERY_METHOD && (
                      <div className="text-gray-500 text-sm items-center flex space-x-1">
                        <MailIcon className="w-4 h-4 text-gray-400" />
                        <span>
                          {DateTime.fromISO(item.purchaseable_details.delivery_date).toLocaleString(
                            DateTime.DATE_MED
                          )}
                        </span>
                      </div>
                    )}
                  </div>
                )}

                {item.will_be_waitlisted && (
                  <div className="text-gray-500 text-sm items-center flex space-x-1">
                    <ClipboardListIcon className="w-4 h-4" />
                    <span>{t('frontend.basket_slideover.basket_item.waitlist')}</span>
                  </div>
                )}
              </div>
            </div>

            <div className="font-medium text-right space-y-1">
              {!hidePrices && (
                <div>
                  {formatMoney({
                    amount: overridePriceAmount ?? item.price,
                    currency: item.currency,
                  })}
                </div>
              )}
              {_canEdit && (
                <button
                  className="block relative hover:underline text-accent text-sm font-medium ml-auto"
                  title={t('frontend.basket_slideover.basket_item.edit')}
                  onClick={onEdit}
                >
                  {t('frontend.basket_slideover.basket_item.edit')}
                </button>
              )}
              {canRemove && (
                <button
                  className="block relative hover:underline text-accent text-sm font-medium ml-auto"
                  title={t('frontend.basket_slideover.basket_item.remove')}
                  onClick={handleRemove}
                  disabled={isRemoving}
                >
                  {t('frontend.basket_slideover.basket_item.remove')}
                  {isRemoving && (
                    <div className="absolute inset-0 flex items-center justify-center bg-white">
                      <Spinner height="h-4" width="w-4" />
                    </div>
                  )}
                </button>
              )}
            </div>
          </div>

          {item.offering_type === 'package' && (
            <>
              <ol className="mt-2 pl-5 list-disc space-y-1 text-gray-700 marker:text-gray-300 relative">
                <div className="absolute h-full border-l top-0 left-1.5 border-gray-300" />
                {item.package_items.map((packageItem) => (
                  <BasketPackageItem
                    key={packageItem.id}
                    packageItem={packageItem}
                    hidePrices={hidePrices}
                    currency={item.currency}
                    acrossMultipleDays={isAcrossMultipleDays(item)}
                  />
                ))}
              </ol>
            </>
          )}
        </div>
      </div>
    </motion.li>
  )
}

const CheckInOutTimes = ({ item }) => {
  const hotelRoomChoice = (item.package_items ?? []).find(
    (item) => item.offering_type === 'hotel_room_reservation'
  )
  const checkInDate = DateTime.fromISO(hotelRoomChoice.item_configuration.check_in_date)
  const checkOutDate = DateTime.fromISO(hotelRoomChoice.item_configuration.check_out_date)

  return (
    <div>
      <div className="text-gray-500 text-sm items-center flex space-x-1">
        <ArrowRightIcon className="w-4 h-4 text-gray-400" />
        <span>Check in {checkInDate.toLocaleString(DateTime.DATE_MED)}</span>
      </div>
      <div className="text-gray-500 text-sm items-center flex space-x-1">
        <ArrowLeftIcon className="w-4 h-4 text-gray-400" />
        <span>Check out {checkOutDate.toLocaleString(DateTime.DATE_MED)}</span>
      </div>
    </div>
  )
}

const BasketPackageItem = ({ packageItem, hidePrices, currency, acrossMultipleDays = false }) => {
  const duration = useFormatFromNumberOfMinutes(packageItem.duration)

  // If the package takes place across multiple days,
  // we'll show the date and time next to each package item.
  // Otherwise, we'll just show the time.
  const timeFormat = acrossMultipleDays ? DateTime.DATETIME_MED : DateTime.TIME_SIMPLE

  return (
    <li className="text-sm relative z-10">
      <div className="font-medium inline-flex w-full space-x-2">
        <span className="flex-1 truncate" title={packageItem.offering_name}>
          {packageItem.offering_name}
        </span>
        {!hidePrices && packageItem.price_change > 0 && (
          <span>
            +
            {formatMoney({
              amount: packageItem.price_change,
              currency: currency,
            })}
          </span>
        )}
      </div>

      {(packageItem.time || packageItem.duration) &&
        packageItem.item_configuration?.hide_times !== true &&
        packageItem.offering_type !== 'hotel_room_reservation' && (
          <div className="flex space-x-2 text-gray-500">
            {packageItem.booking_summary?.start_time && (
              // It could either be a string or a date object
              <span>
                {typeof packageItem.booking_summary.start_time === 'string' &&
                  DateTime.fromISO(packageItem.booking_summary.start_time).toLocaleString(
                    timeFormat
                  )}

                {typeof packageItem.booking_summary.start_time === 'object' &&
                  DateTime.fromJSDate(packageItem.booking_summary.start_time).toLocaleString(
                    timeFormat
                  )}
              </span>
            )}
            {!packageItem.booking_summary?.start_time && packageItem.time && (
              // It could either be a string or a date object
              <span>
                {typeof packageItem.time === 'string' &&
                  DateTime.fromISO(packageItem.time).toLocaleString(timeFormat)}

                {typeof packageItem.time === 'object' &&
                  DateTime.fromJSDate(packageItem.time).toLocaleString(timeFormat)}
              </span>
            )}

            {packageItem.duration && <span>{duration}</span>}
          </div>
        )}
    </li>
  )
}

import { useState } from 'react'
import { useOffering } from '../AvailabilityCheck/Context'
import { usePackageSlots } from '../OrderHooks'
import { DateTime } from 'luxon'
import { Spinner } from '../Spinner'
import { useMemo } from 'react'
import { formatMoney } from '../Money'
import { useTranslation } from 'react-i18next'
import { ChoiceOptionV2 } from './ChoiceOptionV2'
import { ChevronRightIcon } from '@heroicons/react/outline'
import { every, map } from 'lodash'
import { WeekDayPicker } from '../WeekDayPicker'

const SelectedChoiceSummary = ({ selections }) => {
  const selectionText = selections
    .map((selection) => {
      const hideTimes = selection.item_configuration?.hide_times === true
      let str = selection.offering_name

      if (selection.time && !hideTimes) {
        str += ` at ${DateTime.fromISO(selection.time).toLocaleString(DateTime.TIME_SIMPLE)}`
      }

      return str
    })
    .join(', ')

  return <div>{selectionText}</div>
}

const OptionalTag = () => {
  const { t } = useTranslation()

  return (
    <span className="relative -top-[1px] ml-2 rounded-lg bg-gray-100 p-1 px-2 text-xs font-medium text-gray-500">
      {t('frontend.check_availability.package_config.optional')}
    </span>
  )
}

const PickInstructions = ({ maxOptions = 1, budget = null, remainingBudget = 0, currency }) => {
  const { t } = useTranslation()

  const budgetTxt = useMemo(() => {
    if (budget > 0 && remainingBudget >= 0) {
      return (
        <span
          dangerouslySetInnerHTML={{
            __html: t('frontend.check_availability.package_config.budget_remainder', {
              budget: formatMoney({ amount: remainingBudget, currency }),
            }).replace(/\*\*(.*?)\*\*/g, '<span class="font-medium text-accent">$1</span>'),
          }}
        />
      )
    } else if (budget > 0 && remainingBudget < 0) {
      return (
        <span
          dangerouslySetInnerHTML={{
            __html: t('frontend.check_availability.package_config.budget_exceeded', {
              excess: formatMoney({
                amount: Math.abs(remainingBudget),
                currency,
              }),
            }).replace(/\*\*(.*?)\*\*/g, '<span class="font-medium text-accent">$1</span>'),
          }}
        />
      )
    }
  }, [budget, remainingBudget, currency])

  const optionsTxt = useMemo(() => {
    if (maxOptions === 1) {
      // They can only pick one, so no text is needed here.
      return null
    }

    if (maxOptions === null) {
      return t('frontend.check_availability.package_config.unlimited_max_options')
    }

    return t('frontend.check_availability.package_config.specific_maximum_options', {
      count: maxOptions,
    })
  }, [maxOptions])

  if (!optionsTxt && !budgetTxt) {
    return null
  }

  return (
    <div className="text-xs text-gray-500 mt-1 text-center">
      {optionsTxt && <p>{optionsTxt}</p>}
      {budgetTxt && <p>{budgetTxt}</p>}
    </div>
  )
}

export const ChoiceV2 = ({
  choice,
  index,
  canEdit,
  onAdd,
  onRemove,
  onUpdateBasketItem,
  onContinue,
  onShowTimePicker,
  onEdit,
  expanded,
  itemsForChoice,
  requiresSlots,
  autoSelectTimeslot = false,
  budget = {},
  hidePrices = false,
  totalChoices,
}) => {
  const { t } = useTranslation()
  const [editing, setEditing] = useState(false)
  const {
    id,
    date,
    basket,
    basketId,
    basketItemId,
    currency,
    allowsSelectingHotelRoom,
    checkOutDate,
  } = useOffering()

  const [choiceDate, setChoiceDate] = useState(date)

  const basketItem = basket.items.find((item) => item.id === basketItemId)

  const shouldShowChangeButton = useMemo(() => {
    return !expanded && itemsForChoice !== undefined && canEdit
  }, [itemsForChoice, expanded, canEdit])

  const numItemsPerGuest = (basketItem?.guests ?? []).reduce((itemsPerGuest, guest) => {
    itemsPerGuest[guest.id] = itemsPerGuest[guest.id] ?? 0
    const packageItems = (basketItem.package_items ?? []).filter(
      (packageItem) => packageItem.choice_id === choice.id
    )

    packageItems.forEach((packageItem) => {
      const itemGuests = packageItem.guests ?? basketItem?.guests ?? []

      itemGuests.forEach((itemGuest) => {
        if (itemGuest.id === guest.id) {
          itemsPerGuest[guest.id]++
        }
      })
    })

    return itemsPerGuest
  }, {})

  const minOptionsOk = every(numItemsPerGuest, (numItems) => numItems >= choice.min_options)
  const maxOptionsOk =
    choice.max_options === null ||
    every(numItemsPerGuest, (numItems) => numItems <= choice.max_options)

  const makeChoice = async (option, vals = {}, optionIndex) => {
    // null means "No thanks", or "Next" when unlimited choices.
    // No need to update basket.
    if (option === null) {
      await onContinue()

      return
    }

    let guestsWithoutChoice = []

    if (option !== undefined && option.offering.min_guests < (basketItem?.guests ?? []).length) {
      // Get the all guests who don't have an item for this choice yet
      guestsWithoutChoice = getGuestsWithoutChoice(basketItem, choice)
    }

    await onAdd(
      {
        choice_id: choice.id,
        option_id: option.id,
        ...vals,
      },
      optionIndex
    )

    // Continue to the next step unless
    // a) It's shop-til-you-drop
    // b) Some guests don't have an option for this choice
    if (choice.max_options !== null && guestsWithoutChoice.length === 0) {
      onContinue()
    }
  }

  const removeChoice = async (option) => {
    for (const packageItem of itemsForChoice.filter((i) => i.offering_id === option.offering.id)) {
      await onRemove(packageItem.id)
    }
  }

  // Handle updating a shared basket item rather than a choice option
  const updateBasketItem = async (basketItemId, vals) => {
    await onUpdateBasketItem(basketItemId, vals)

    // It's not shop-til-you-drop, so tell the parent to progress to the next stage.
    if (choice.max_options !== null) {
      onContinue()
    }
  }

  const handleEdit = async () => {
    setEditing(true)
    await onEdit()
    setEditing(false)
  }

  const handleRequireSlot = (_, optionIndex) => {
    onShowTimePicker(optionIndex)
  }

  const choiceAlreadyMade = choice.max_options === 1 && itemsForChoice !== undefined

  const shouldShowCta = choice.max_options === null || choice.max_options > 1 || choiceAlreadyMade

  return (
    <div data-trybe-element="package-choice">
      <div className="px-4 space-y-1">
        <div
          className="text-xs uppercase text-accent"
          dangerouslySetInnerHTML={{
            __html: t('frontend.check_availability.package_config.step', {
              step: index,
              total: totalChoices,
            }).replace(/\*\*(.*?)\*\*/g, '<span class="font-medium">$1</span>'),
          }}
        />
        <div>
          <div className="flex justify-between">
            <div className="flex-1">
              <h1 className="text-lg font-medium text-gray-800 flex justify-between items-start">
                <div>
                  {choice.name}
                  {choice.optional && <OptionalTag />}
                </div>
              </h1>
            </div>
            <div className="flex-shrink-0">
              {shouldShowChangeButton && (
                <button
                  className="flex-shrink-0 relative -top-1 -right-1 text-sm font-medium text-accent p-2 rounded-md hover:bg-accent/10 disabled:hover:bg-white disabled:cursor-not-allowed"
                  title={t('frontend.check_availability.package_config.change_option')}
                  onClick={() => handleEdit()}
                  disabled={editing}
                  data-trybe-element="package-choice-change-button"
                >
                  {editing ? (
                    <Spinner width="w-4" height="h-4" margin="my-1" />
                  ) : (
                    t('frontend.check_availability.package_config.change_option')
                  )}
                </button>
              )}
            </div>
          </div>

          <div>
            <h2 className="text-sm text-gray-500">
              {expanded && choice.description}

              {!expanded && <SelectedChoiceSummary selections={itemsForChoice} />}
            </h2>
          </div>

          {allowsSelectingHotelRoom && (
            <div className="my-4 space-y-2">
              <div className="text-xs uppercase text-gray-500">Date</div>

              <WeekDayPicker
                dateFrom={date}
                dateTo={checkOutDate}
                onDateSelected={setChoiceDate}
                selected={choiceDate}
              />
            </div>
          )}
        </div>
      </div>

      {expanded && (
        <>
          <div className={`mt-4 mx-4 divide-y divide-gray-100`}>
            {choice.optional && (
              <button
                className="py-3 flex leading-7 items-start space-x-3 w-full text-left cursor-pointer bg-white focus:ring-accent focus:ring-2 focus:ring-offset-1"
                onClick={() => makeChoice(null)}
                title={t('frontend.check_availability.package_config.no_thanks')}
                data-trybe-element="package-choice-skip-button"
              >
                <div className="flex-1">
                  {t('frontend.check_availability.package_config.no_thanks')}
                </div>

                <div className="flex-shrink-0">
                  <ChevronRightIcon className="w-5 h-5" />
                </div>
              </button>
            )}

            {choice.options.map((option, index) => (
              <ChoiceOptionV2
                choice={choice}
                choiceDate={choiceDate}
                itemsForOption={itemsForChoice.filter((i) => i.option_id === option.id)}
                option={option}
                key={index}
                onAdd={(vals) => makeChoice(option, { ...vals }, index)}
                onUpdateBasketItem={(basketItemId, vals) =>
                  updateBasketItem(basketItemId, { ...vals }, index)
                }
                onRemove={() => removeChoice(option)}
                requiresSlots={requiresSlots}
                autoSelectTimeslot={autoSelectTimeslot}
                onRequireSlot={() => handleRequireSlot(option.id, index)}
                getSlotsHook={() =>
                  usePackageSlots(id, choice.id, choiceDate, basketId, basketItemId, option.id, {
                    refetchOnWindowFocus: false,
                    enabled: requiresSlots,
                  })
                }
                shouldShowTimes={choice.hide_times !== true}
                showFromPrice={!hidePrices && budget.budget > 0}
                shouldShowPriceChange={!hidePrices && option.price_change > 0 && !budget?.budget}
              />
            ))}
          </div>

          {shouldShowCta && (
            <div className="sticky inset-x-0 bottom-0 border-t border-gray-200 bg-white/75 p-4 backdrop-blur-sm">
              <button
                className={`bg-accent text-on-accent disabled:cursor-not-allowed disabled:opacity-75 mt-2 block text-center shadow-sm hover:bg-accent/80 disabled:hover:bg-accent font-medium rounded-lg w-full p-2 px-4 cursor-pointer focus:ring-accent focus:ring-2 focus:ring-offset-1`}
                onClick={() => makeChoice(null)}
                disabled={!minOptionsOk || !maxOptionsOk}
                title={t('frontend.check_availability.package_config.next')}
                data-trybe-element="package-choice-continue-button"
              >
                {t('frontend.check_availability.package_config.next')}
              </button>
              {!minOptionsOk && (
                <p className="text-xs text-gray-500 mt-1 text-center">
                  {t(
                    'frontend.check_availability.package_config.select_at_least_before_continuing',
                    {
                      count: choice.min_options,
                    }
                  )}
                </p>
              )}
              {!maxOptionsOk && (
                <p className="text-xs text-gray-500 mt-1 text-center">
                  {t('frontend.check_availability.package_config.select_up_to_before_continuing', {
                    count: choice.max_options,
                  })}
                </p>
              )}

              <PickInstructions
                maxOptions={choice.max_options}
                budget={!hidePrices ? budget.budget : 0}
                remainingBudget={budget.remaining}
                currency={currency}
              />
            </div>
          )}
        </>
      )}
    </div>
  )
}

export const getGuestsWithoutChoice = (basketItem, choice) => {
  return (basketItem?.guests ?? []).filter((guest) => {
    return (
      basketItem.package_items.find(
        (packageItem) =>
          packageItem.choice_id === choice.id &&
          (packageItem.guests === undefined || map(packageItem.guests, 'id').includes(guest.id))
      ) === undefined
    )
  })
}

export const getGuestsForOffering = (guests, offering) => {
  if (!offering.min_guests || offering.type !== 'appointment') {
    return undefined
  }

  return guests.splice(0, offering.min_guests)
}
